import { German } from 'flatpickr/dist/l10n/de';
// import { english } from 'flatpickr/dist/l10n/default';
// import { Spanish } from 'flatpickr/dist/l10n/es';
// import { French } from 'flatpickr/dist/l10n/fr';
// import { Italian } from 'flatpickr/dist/l10n/it';

German.rangeSeparator = ' - ';

export default {
  de: German,
  // en: english,
  // es: Spanish,
  // fr: French,
  // it: Italian,
};
