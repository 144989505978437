import { computed } from '@vue/composition-api';

export default (ctx) => {
  const currentLocale = computed(() => ctx.root.$store.state.app.currentLocale);
  const currentCurrency = computed(() => ctx.root.$store.state.app.currentCurrency);

  const formatCurrency = (
    number, currency, locale, opts = {},
  ) => Intl.NumberFormat(locale || currentLocale.value, {
    style: 'currency',
    currency: currency || currentCurrency.value,
    ...opts,
  }).format(number);

  const formatNumber = (
    number, locale = currentLocale.value, opts = {},
  ) => Intl.NumberFormat(locale, {
    style: 'decimal',
    ...opts,
  }).format(number);

  return { formatCurrency, formatNumber };
};
