import { mapGetters } from 'vuex';

const ActiveObjectStore = {
  computed: {
    ...mapGetters([
      'getActiveObjectData',
      'hasActiveObjectData',
      'getActiveObjectNumber',
      'getObjectDataByKey',
    ]),
    objectData() {
      return this.getActiveObjectData;
    },
    hasObjectData() {
      return this.hasActiveObjectData;
    },
    activeObjectNumber() {
      return this.getActiveObjectNumber;
    },
    hasObjectVacancy() {
      return this.objectData.availability.count > 0;
    },
    hasObjectAexeaDesc() {
      return this.objectData.descriptionsAexea.description !== '';
    },
    isActive() {
      return this.getObjectDataByKey('active') > 0;
    },
    disabled() {
      const disabledData = this.getObjectDataByKey('disabled');
      return disabledData?.days > 0;
    },
    bookable() {
      return this.hasObjectVacancy && this.isActive && !this.disabled;
    },
  },
};

export default ActiveObjectStore;
