<template>
  <div
    ref="flatpickrGroup"
    class="flatpickr-base"
    :class="variant"
  >
    <template v-if="flatpickrMounted && canInit">
      <validation-provider
        v-slot="{ errors, valid }"
        :name="$t('flatpickr:travelDate')"
        :rules="rules"
        vid="flatpickrvid"
        mode="eager"
        tag="div"
      >
        <div
          v-show="flatpickrComponentReady"
          class="form-group"
          :class="{ disabled: disabled }"
        >
          <label
            class="input-group flatpickr-ignore"
          >
            <span
              class="input-group-prepend"
            >
              <span
                class="input-group-text"
              >
                <i class="icon"><icon-calender /></i>
              </span>

            </span>

            <flat-pickr
              :key="flatpickrUpdateKey"
              :value="flatpickrDefaultValue"
              :class="{'form-control' : true, 'is-invalid': !valid && errors.length > 0}"
              :aria-invalid="!valid && errors.length > 0"
              :config="flatpickrConfig"
              :events="flatpickrBaseEvents"
              :placeholder="placeholderText"
              autocomplete="off"
              state="error"
              @on-change="dateChange"
              @on-close="pickerClosed"
              @on-ready="checkReady"
              @input="emitInput"
            />
            <div
              v-if="monthNavigation"
              class="flatpickr-month-navigation"
            >
              <button
                v-if="fpInstance && !fpInstance._hidePrevMonthArrow"
                type="button"
                class="prev"
                :data-ident="`fpPrevMonth`"
                @click="fpInstance.changeMonth(-1)"
              >
                <i class="icon"><icon-chevron-left /></i>
                {{ monthNavigationData.prevMonth.label }}
              </button>
              <button
                v-if="fpInstance && !fpInstance._hideNextMonthArrow"
                type="button"
                class="next"
                :data-ident="`fpNextMonth`"
                @click="fpInstance.changeMonth(1)"
              >
                {{ monthNavigationData.nextMonth.label }}
                <i class="icon"><icon-chevron-right /></i>
              </button>
            </div>
            <div
              v-show="showOverlay"
              v-if="mode === 'range'"
              ref="showOverlay"
              :style="{ width: `${flatpickrInputWidth - 3}px`, height: `${flatpickrInputHeight - 3}px` }"
              class="flatpickr-input-overlay flatpickr-ignore"
            >
              <div :class="{ 'active': overlayStartDateActive }">
                {{ overlayStartDate }}
              </div>
              <div class="flatpickr-input-overlay-separator">
                {{ overlaySeparator }}
              </div>
              <div :class="{ 'active': overlayEndDateActive }">
                {{ overlayEndDate }}
              </div>
            </div>
            <button
              v-if="clearButtonShow"
              class="form-control-reset"
              type="button"
              :aria-label="$t('common:ariaLabel.dateResetButton')"
              :data-ident="`dateResetButton`"
              @click="resetDate($event)"
            >
              <i class="icon"><icon-times /></i>
            </button>
          </label>
        </div>

        <div
          v-if="errors.length > 0"
          class="invalid-feedback showInvalid"
        >
          {{ errors[0] }}
        </div>
      </validation-provider>
    </template>
    <div
      v-show="!flatpickrComponentReady"
      class="form-group"
      :class="{ disabled: disabled }"
    >
      <validation-provider
        v-slot="{ errors,valid }"
        :name="$t('flatpickr:travelDate')"
        :rules="rules"
        vid="flatpickrvid"
        mode="aggressive"
        tag="div"
      >
        <label
          class="input-group"
        >
          <div
            class="input-group-prepend"
          >
            <span
              class="input-group-text flatpickr-ignore"
            >
              <i class="icon"><icon-calender /></i>
            </span>

          </div>

          <input
            :id="identy !== '' ? identy : false"
            type="text"
            :placeholder="placeholderText || overlayStartDate+' '+overlaySeparator+' '+overlayEndDate"
            :value="selectedStartDateString !== '' ? overlayStartDate+' '+overlaySeparator+' '+overlayEndDate : ''"
            autocomplete="off"
            class="form-control flatpickr-input flatpickr-ignore"
            readonly="readonly"
            :class="{'form-control' : true, 'is-invalid': !valid && errors.length > 0}"
            :data-ident="`fpStartDateInput`"
            :aria-invalid="!valid && errors.length > 0"
            @click="initPicker"
          >
          <button
            v-if="clearButtonShow"
            class="form-control-reset"
            type="button"
            :aria-label="$t('common:ariaLabel.dateResetButton')"
            :data-ident="`dateResetButton`"
            @click="resetDate($event)"
          >
            <i class="icon"><icon-times /></i>
          </button>
        </label>
        <div
          v-if="errors.length > 0"
          class="invalid-feedback showInvalid"
        >
          {{ errors[0] }}
        </div>
      </validation-provider>
    </div>
  </div>
</template>

<script>
// import flatPickr from 'vue-flatpickr-component';
import vacancyHelper from 'utils/vacancyHelper';
import i18nHelper from 'utils/i18nHelper';
import { toAPIDateString, toDateString } from 'utils/DateHelper';
import {
  subDays, differenceInDays, format, isValid,
} from 'date-fns';
import de from 'locales/de/veeValidateMessages.json';
import flatpickrLocale from 'locales/flatpickr/locale';

/* ICONS */

import IconTimes from 'ICON:IconTimes';
import IconCalender from 'ICON:IconCalender';
import IconChevronLeft from 'ICON:IconChevronLeft';
import IconChevronRight from 'ICON:IconChevronRight';


import formsComputedProperties from 'mixins/Forms';

// calculate tomorrow for flatpickr
const today = new Date();
const tomorrow = new Date();
tomorrow.setDate(today.getDate() + 1);

/**
 *
 * @@author        j0Shi
 * @@date          2019.07.04
 * @@desc          Base functionality for flatpickr
 * @@usedby        ObjectVacancyForm -> Object
 *                 Search
 *                 Search -> SearchFilterMenu
 *                 Booking
 *
 */
export default {
  name: 'FlatPickrBase',
  i18nOptions: { namespaces: ['flatpickr', 'date'] },
  components: {
    flatPickr: () => import(/* webpackChunkName: "lazychunk-flatpickr" */ 'vue-flatpickr-component'),
    IconCalender,
    IconTimes,
    IconChevronLeft,
    IconChevronRight,
    ValidationProvider: () => import(/* webpackChunkName: "lazychunk-validation-provider" */ 'utils/veeValidateProvider'),
  },
  mixins: [formsComputedProperties],
  props: {
    // can be used to disable the picker input
    disabled: {
      type: Boolean,
      default: false,
    },
    // flatpickr option => displays the calendar inline
    inline: {
      type: Boolean,
      default: false,
    },
    // picker doesn't handle positioning too well and sometimes we want it to align right
    forceRightMost: {
      type: Boolean,
      default: false,
    },
    // vacancy data; if provided, picker will have additional functionality
    vacancyData: {
      type: Object,
      default: () => {},
    },
    // how many months are shown in the picker
    monthCount: {
      type: Number,
      default: 1,
    },
    // show alternative months navigation below the picker
    monthNavigation: {
      type: Boolean,
      default: false,
    },
    // range|single (pick multiple dates or just one)
    mode: {
      type: String,
      default: 'range',
    },
    // whether or not to show a legend
    showLegend: {
      type: Boolean,
      default: true,
    },
    // used to set id
    identy: {
      type: String,
      required: false,
      default: '',
    },
    // static value is needed for sticky components that import the picker
    static: {
      type: Boolean,
      default: false,
    },
    // wrap value is needed for situations in which the picker should not scroll with the page
    wrap: {
      type: Boolean,
      default: false,
    },
    // open datepicker if reset ist clicked
    openOnReset: {
      type: Boolean,
      default: false,
    },
    // value used for v-model
    value: {
      type: [Array, Object, String, Date],
      default: '',
    },
    // emit additional data with input event
    extendedInputEmit: {
      type: Boolean,
      default: false,
    },
    // whether to show a reset button in the picker input field
    showClearButton: {
      type: Boolean,
      default: true,
    },
    // pass viewed month/year (mainly used to to re-initialize correctly)
    viewedDate: {
      default: () => new Date(),
      type: [Date, String],
    },
    // placeholder text to show when there's no date selected
    placeholder: {
      type: String,
      default: '',
    },
    // on date change only fill store and don't trigger callbacks
    onlyFillStore: {
      type: Boolean,
      default: false,
    },
    // vee-validate rules for validating picker input
    rules: {
      type: String,
      default: '',
    },
    // external boolean marker to init the picker
    canInit: {
      type: Boolean,
      default: true,
    },
    maxDuration: {
      type: Number,
      default: 0,
    },
    openDirectly: {
      type: Boolean,
      default: false,
    },
    validationMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // vacancy helper from utils/vacancyHelper
      helper: vacancyHelper(this.vacancyData),
      i18nHelper: i18nHelper(this.$i18n.i18next),
      // config for vue-flatpickr-component
      flatpickrBaseConfig: {
        minDate: new Date(tomorrow.setHours(12, 0, 0, 0)),
        monthSelectorType: 'static',
        disable: [],
        locale: 'en',
        onDayCreate: () => {},
        onOpen: () => {},
        onClose: () => {},
        onMonthChange: () => {},
        onYearChange: () => {},
        onReady: () => {},
      },
      // events that get emitted by vue-flatpickr-component
      flatpickrBaseEvents: ['onChange', 'onClose', 'onReady', 'onOpen', 'onMonthChange', 'onYearChange'],
      // this callback is run whenever the calendar chenges months, year, or opens
      dayFormatCallback: () => {},
      // hard-coded max range in days for the picker
      maxRange: 28,
      // ready state for flatpickr lazy loading
      flatpickrComponentReady: false,
      // turns true when the picker was mounted
      // used to show an alternate input as long as the picker is still setting up
      flatpickrMounted: false,
      // overlay state
      showOverlay: false,
      overlayStartDateActive: false,
      overlayEndDateActive: false,
      // selected values
      selectedStartDateString: '',
      selectedEndDateString: '',
      // https://michaelnthiessen.com/force-re-render/
      // need to force re-render of picker because changes to showMonths and other config items won't work otherwise
      flatpickrUpdateKey: 0,
      // width of the fp input element, need this to set same width on overlay
      flatpickrInputWidth: 0,
      flatpickrInputHeight: 0,
      // current fpInstance
      flatpickrInstance: null,
    };
  },
  computed: {
    variant() {
      const experiment = this.$store.getters.getExperimentById('experiment_edvue-2680');
      const variant = parseInt(experiment?.variant, 10);
      return variant === 1 ? 'variant' : 'org';
    },
    // @vuese
    // language from the store
    currentLanguage() {
      return this.$store.state.app.currentLanguage;
    },
    // @vuese
    // returns the flatpickr instance object
    // https://flatpickr.js.org/
    fpInstance() {
      if (!this.flatpickrComponentReady) {
        return false;
      }

      return this.flatpickrInstance;
    },
    // @vuese
    // set placeholder if exists or use default one in the language vars
    placeholderText() {
      if (this.placeholder === '') {
        return this.lang.pickerInputAlternate.full + this.required;
      }
      return this.placeholder + this.required;
    },
    // @vuese
    // checks the existence of vacancy data, component does extra stuff if this is the case
    hasVacancyData() {
      if (typeof this.vacancyData === 'undefined') return false;
      if (this.vacancyData.count === 0) return false;
      return true;
    },
    // @vuese
    // some component specific language vars that we need
    lang() {
      return {
        pickerInputAlternate: {
          full: this.$t('flatpickr:pickerInputAlternate.full'),
          departure: this.$t('flatpickr:pickerInputAlternate.departure'),
          separator: this.$t('flatpickr:pickerInputAlternate.separator'),
          arrival: this.$t('flatpickr:pickerInputAlternate.arrival'),
          rangeSeparator: this.$t('flatpickr:pickerInputAlternate.rangeSeparator'),
          dateFnsDateFormat: this.$t('date:dateFnsDateFormat'),
        },
      };
    },
    // @vuese
    // merges reactive flatpickr options into static ones
    flatpickrConfig() {
      return {
        ...this.flatpickrBaseConfig,
        showMonths: this.monthCount,
        static: this.static,
        wrap: this.static || this.wrap,
        mode: this.mode,
        inline: this.inline,
        dateFormat: this.$t('date:flatpickrDateFormat'),
      };
    },
    // @vuese
    // sets the value of the overlay start date
    overlayStartDate() {
      return this.selectedStartDateString || this.lang.pickerInputAlternate.departure;
    },
    // @vuese
    // sets the value of the overlay end date
    overlayEndDate() {
      return this.selectedEndDateString || this.lang.pickerInputAlternate.arrival;
    },
    // @vuese
    // data needed for custom month navigation
    monthNavigationData() {
      if (!this.fpInstance) {
        return {
          nextMonth: {
            label: '',
            year: '',
          },
          prevMonth: {
            label: '',
            year: '',
          },
        };
      }

      const { currentMonth, currentYear } = this.fpInstance;
      const nextMonth = currentMonth + this.monthCount > 11 ? (currentMonth - 12 + this.monthCount) : currentMonth + this.monthCount;
      const prevMonth = currentMonth - 1 < 0 ? 11 : currentMonth - 1;
      const nextMonthLabel = this.fpInstance.l10n.months.longhand[nextMonth];
      const prevMonthLabel = this.fpInstance.l10n.months.longhand[prevMonth];
      const nextMonthYear = currentMonth + this.monthCount > 11 ? currentYear + 1 : currentYear;
      const prevMonthYear = currentMonth - 1 < 0 ? currentYear - 1 : currentYear;

      return {
        nextMonth: {
          label: nextMonthLabel,
          year: nextMonthYear,
        },
        prevMonth: {
          label: prevMonthLabel,
          year: prevMonthYear,
        },
      };
    },
    // @vuese
    // sets the value of the overlay range separator
    overlaySeparator() {
      return this.selectedStartDateString === ''
        ? this.lang.pickerInputAlternate.separator : this.lang.pickerInputAlternate.rangeSeparator;
    },
    // @vuese
    // look for a string value and transform it into an array of strings in range mode
    // this is a workaround for a wonky v-model behavior
    // v-model only accepts a string, but one string in range mode as default will
    // only set one date and not both
    flatpickrDefaultValue() {
      if (typeof this.value === 'string' && this.mode === 'range' && this.value !== '') {
        return this.value.split(this.lang.pickerInputAlternate.rangeSeparator);
      }
      if (this.mode === 'range') {
        if (this.value.length > 1 && this.value[0] === false) {
          return '';
        }
      }
      return this.value;
    },
    // used to show the clear button if date is chosen
    clearButtonShow() {
      return (this.selectedStartDateString && this.showClearButton);
    },
  },
  watch: {
    // @vuese
    // once vacancy data props changes, fire the process function
    vacancyData: {
      handler: 'processVacancyData',
    },
    // @vuese
    // watch month count to force re-render
    monthCount: {
      handler: 'forcePickerReRender',
    },
    // @vuese
    // watch language change to update locale, which by itself is not reactive
    currentLanguage: {
      handler: 'updateLocale',
    },
    // @vuese
    // watch value to modify selected start and end dates
    value: {
      handler: 'setSelectedDateStrings',
    },
    // @vuese
    // watch route to modify selected start and end dates
    $route: {
      handler: 'setSelectedDateStrings',
    },
    // @vuese
    // watch flatpickrComponentReady to set ignore elementes which are not in dom before flatpickr is inserted
    // eslint-disable-next-line object-shorthand
    flatpickrComponentReady: function flatpickrComponentReadyWatcher(newValue) {
      if (newValue) {
        const ignoredElements = document.querySelectorAll('.flatpickr-ignore');
        if (this.flatpickrInstance.config.ignoredFocusElements.length !== ignoredElements.length) {
          this.flatpickrInstance.set('ignoredFocusElements', [
            ...ignoredElements,
          ]);
          this.dayFormatCallback();
        }
      }
    },
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') window.removeEventListener('resize', this.setFlatpickrInputDimensions);
  },
  mounted() {
    if (this.inline || this.openDirectly) this.initPicker();
  },
  created() {
    // try to set window listener to monitor fp input width
    if (typeof window !== 'undefined') window.addEventListener('resize', this.setFlatpickrInputDimensions);

    // init date strings with params from store or query to correctly show values in placeholder input
    this.setSelectedDateStrings();
  },
  methods: {
    // @vuese
    // walk through different source types for dates and set selected ones
    setSelectedDateStrings() {
      this.i18nHelper.checkKey('date:dateFnsDateFormat').then(() => {
        const pickerStartDate = Array.isArray(this.flatpickrDefaultValue) ? this.flatpickrDefaultValue[0] : this.flatpickrDefaultValue;
        const pickerEndDate = Array.isArray(this.flatpickrDefaultValue) ? this.flatpickrDefaultValue[1] : '';
        this.selectedStartDateString = typeof pickerStartDate !== 'string' && isValid(pickerStartDate) ? format(pickerStartDate, this.$t('date:flatpickrOverlayDateFormat')) : pickerStartDate;
        this.selectedEndDateString = typeof pickerEndDate !== 'string' && isValid(pickerEndDate) ? format(pickerEndDate, this.$t('date:flatpickrOverlayDateFormat')) : pickerEndDate;
      }).catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
    },
    // @vuese
    // simple setter for fp input width
    setFlatpickrInputDimensions() {
      if (this.fpInstance) {
        this.flatpickrInputWidth = this.fpInstance.input.offsetWidth;
        this.flatpickrInputHeight = this.fpInstance.input.offsetHeight;
      }
    },
    // @vuese
    // method to init flapickr; this has to be run at some point to use the picker
    initPicker() {
      // load required date namespace, then set up picker
      Promise.all([
        this.i18nHelper.checkKey('date:calendarDateFormat'),
        this.i18nHelper.checkKey('flatpickr:pickerInputAlternate.full'),
      ])
        .catch(() => {
          // eslint-disable-next-line no-console
          console.warn('couldn\'t fetch flatpickr languages, using default instead');
        })
        .finally(() => {
          import(/* webpackChunkName: "lazychunk-validation-provider" */ 'utils/veeValidateProvider')
            .then((module) => {
              module.setInteractionMode('lazy');
              module.localize('de', de);
              return import('flatpickr/dist/flatpickr.css');
            })
            .finally(() => {
              this.buildFlatpickr();
              this.flatpickrMounted = true;
            });
        });
    },
    // @vuese
    // sets the max date for the vacancy data set
    getMaxDate() {
      return this.hasVacancyData ? this.helper.lastArrivalDayInData : this.helper.getMaxDay;
    },
    // @vuese
    // resets the picker and all dates
    resetDate(event) {
      if (event && !this.openOnReset) { // prevent open datepicker if reset ist clicked
        event.preventDefault();
      }
      if (this.fpInstance) {
        this.fpInstance.clear();
        this.fpInstance.input.blur();
      } else {
        this.$emit('on-change', [[], '', {}]);
        const ret = this.extendedInputEmit ? { inputValue: '', dateArr: [] } : '';
        this.$emit('input', ret);
        this.selectedStartDateString = '';
        this.selectedEndDateString = '';
      }
      if (this.hasVacancyData) {
        this.enableArrivalDates();
        this.dayFormatCallback = this.enableArrivalDates;
      } else {
        this.enableSelectedDate();
        this.dayFormatCallback = this.enableSelectedDate;
      }
      this.fpValue = [];
    },
    // @vuese
    // normally the picker shows days from previous and next month, we disable them
    disableMonthOverlap() {
      document.querySelectorAll('.flatpickr-day.nextMonthDay,.flatpickr-day.prevMonthDay').forEach((el) => {
        el.classList.add('day-disabled');
      });
    },
    // @vuese
    // processes our vaqcancy data and disables unavailable days
    processVacancyData() {
      if (this.hasVacancyData) {
        this.helper = vacancyHelper(this.vacancyData);
        // disable all impossible dates in the picker
        this.flatpickrBaseConfig.disable = this.helper.getDatesByStatusCodes(['n', 'N', 'u', 'U']);
        this.dayFormatCallback = this.enableArrivalDates;
        // workaround for a weird timing bug
        // rarely the picker gets initialized before vacancy data is present
        // in that case, the fpInstance's date has to be manually reset
        // otherwise the picker can get into a weird state
        this.$nextTick(() => {
          if (this.fpInstance !== false) this.fpInstance.setDate(this.flatpickrDefaultValue);
        });
      }
    },
    // @vuese
    // disable today in the picker because it usually makes no sense to pick it
    disableToday() {
      if (this.fpInstance === false) return;
      const todayEl = this.fpInstance.calendarContainer.querySelector('.flatpickr-day.today');
      if (todayEl) {
        todayEl.classList.add('day-disabled');
        todayEl.classList.add('flatpickr-disabled');
      }
    },
    // @vuese
    // function to disable selected date on range picker to prevent user of picking the same date twice
    disableSelectedDate(date = [new Date()]) {
      document.querySelectorAll('.flatpickr-day.selected').forEach((el) => {
        el.classList.add('day-disabled');
      });
      if (this.maxDuration > 0) {
        this.limitDuration(this.maxDuration, date);
      }
      this.disableToday();
    },
    // @vuese
    // function to revert disabling selected dates on range picker to prevent user of picking the same date twice
    enableSelectedDate() {
      document.querySelectorAll('.flatpickr-day').forEach((el) => {
        el.classList.remove('day-disabled');
      });
      this.disableToday();
    },
    limitDuration(duration, date = [new Date()]) {
      if (this.fpInstance === false) return;
      let currentDate = null;
      const sel = '.day,.flatpickr-day';
      this.fpInstance.calendarContainer.querySelectorAll(sel).forEach((el) => {
        if (currentDate === null) currentDate = el.dateObj;
        if (
          differenceInDays(el.dateObj, date[0]) > duration
          || differenceInDays(date[0], el.dateObj) > duration
        ) {
          el.classList.add('day-disabled');
        } else {
          el.classList.remove('day-disabled');
        }
      });
      document.querySelectorAll('.day-y,.prevMonthDay,.nextMonthDay').forEach((el) => {
        el.classList.add('day-disabled');
      });
    },
    // @vuese
    // enables possible departure dates based on the vacancy data
    // @arg Array of Javascript Date Objects
    enableDepartureDates(date = [new Date()]) {
      if (this.fpInstance === false) return;
      let currentDate = null;
      const sel = '.flatpickr-day';
      const apiDateString = toAPIDateString(date[0]);
      const allowedDurations = this.vacancyData.days[apiDateString]?.durations || [];
      let diff = 0;
      this.fpInstance.calendarContainer.querySelectorAll(sel).forEach((el) => {
        if (currentDate === null) currentDate = el.dateObj;
        diff = differenceInDays(el.dateObj, date[0]);
        if (
          // duration is allowed
          allowedDurations.includes(diff)
        ) {
          el.classList.remove('flatpickr-disabled');
          el.classList.remove('day-disabled');
          el.classList.remove('day-y');
          el.classList.add('day-Y');
        } else {
          el.classList.add('day-disabled');
        }

        // update currentTimestamp for past dates or if difference is under 1 day
        if (el.dateObj <= date[0]
            || subDays(el.dateObj, 1) <= currentDate) {
          currentDate = el.dateObj;
        }
      });
      document.querySelectorAll('.day-y,.prevMonthDay,.nextMonthDay').forEach((el) => {
        el.classList.add('day-disabled');
      });
    },
    // @vuese
    // enables possible arrival dates based on the vacancy data
    enableArrivalDates() {
      if (this.fpInstance === false) return;
      this.fpInstance.calendarContainer.querySelectorAll('.flatpickr-day').forEach((el) => {
        el.classList.remove('day-disabled');
      });
      this.fpInstance.calendarContainer.querySelectorAll('.day-y,.prevMonthDay,.nextMonthDay').forEach((el) => {
        el.classList.add('day-disabled');
      });

      // disable dates if you can't stay for minStay days
      const maxDate = this.getMaxDate();
      document.querySelectorAll('.day-Y').forEach((el) => {
        const data = this.helper.getDateData(el.dateObj);
        const loopDate = new Date(el.dateObj);
        for (let i = data.minStay; i > 0; i -= 1) {
          loopDate.setDate(loopDate.getDate() + 1);
          const loopDateData = this.helper.getDateData(loopDate);
          if (typeof loopDateData.status === 'undefined' || loopDateData.status === 'n' || loopDate > maxDate) {
            el.classList.add('day-disabled');
          }
        }
      });
    },
    // @vuese
    // callback for the on-change flatpickr event
    // @arg Array of Javascript Date Objetcs
    // @arg localized date String
    // @arg Flatpickr Instance Object
    dateChange(dateArr, dateStr, fpObj) {
      const emitOnChange = () => {
        this.$emit('on-change', [dateArr, dateStr, fpObj]);
        this.fpInstance.input.blur();
      };

      // check if values changed, otherwise do not emit to prevent double actions
      const dateHasChanged = (this.onlyFillStore || (dateStr === this.value || dateArr.toString() === this.value.toString()));
      const isRange = fpObj.config.mode === 'range';
      switch (dateArr.length) {
        // empty dates, fire callback to invoke reset stuff
        case 0:
          this.selectedStartDateString = '';
          this.selectedEndDateString = '';
          if (dateHasChanged) emitOnChange();
          break;
        // one date selected = enable possible endDates or fire callback for single
        case 1:
          this.selectedStartDateString = toDateString(dateArr[0], this.$t('date:flatpickrOverlayDateFormat'));
          this.selectedEndDateString = '';
          this.overlayStartDateActive = false;
          this.overlayEndDateActive = true;

          if (!isRange && dateHasChanged) emitOnChange();
          if (isRange) {
            if (this.hasVacancyData) {
              // disable dates
              this.enableDepartureDates(dateArr);
              this.dayFormatCallback = this.enableDepartureDates;
            } else {
              this.disableSelectedDate(dateArr);
              this.dayFormatCallback = this.disableSelectedDate;
            }
          }
          break;
        // two dates selected = price request
        case 2:
          this.selectedStartDateString = toDateString(dateArr[0], this.$t('date:flatpickrOverlayDateFormat'));
          this.selectedEndDateString = toDateString(dateArr[1], this.$t('date:flatpickrOverlayDateFormat'));
          if (this.inline) {
            // inline picker reset to start overlay state
            this.overlayStartDateActive = true;
            this.overlayEndDateActive = false;
          } else {
            // all other picker close and reset overlay state
            this.overlayStartDateActive = false;
            this.overlayEndDateActive = false;
          }

          if (isRange && dateHasChanged) emitOnChange();

          if (this.hasVacancyData) {
            this.enableArrivalDates();
            this.dayFormatCallback = this.enableArrivalDates;
          } else {
            this.enableSelectedDate();
            this.dayFormatCallback = this.enableSelectedDate;
          }
          break;
        default:
          break;
      }
    },
    // @vuese
    // clean up when the picker is closed prematurely
    // this is a callback for the flatpickr on-close event
    // @arg Array of Javascript Date Objetcs
    // @arg localized date String
    // @arg Flatpickr Instance Object
    pickerClosed(dateArr, dateStr, fpObj) {
      // do some error prevention in case only one date was picked in a range picker
      const isRange = fpObj.config.mode === 'range';
      if (isRange && dateArr.length !== 2) {
        this.resetDate();
      }
    },
    // @vuese
    // sets a boolean flag once the flatpickr component has lazy-loaded
    // this is used for functions that rely on the flatpickr component
    checkReady(dateArr, dateStr) {
      this.flatpickrComponentReady = true;
      // if (this.monthNavigation) this.addMonthNavigation(fpObj); no longer => EDVUE-684
      this.$emit('on-ready', dateStr);
    },
    // @vuese
    // callback for flatpickr input event
    // @arg value of the flatpickr value
    emitInput(value) {
      if (!this.flatpickrComponentReady) return;
      const isRange = this.fpInstance.config.mode === 'range';
      const dateArr = this.fpInstance.selectedDates;

      const ret = this.extendedInputEmit ? { inputValue: value, dateArr } : value;

      // values already set => return
      if (value === this.value || dateArr.toString() === this.value.toString()) return;

      switch (dateArr.length) {
        // empty dates, fire callback to invoke reset stuff
        case 0:
          // @vuese
          // emits the flatpickr component input event
          // @arg Object { inputValue, dateArr }
          this.$emit('input', ret);
          break;
        // one date selected = enable possible endDates or fire callback for single
        case 1:
          if (!isRange) this.$emit('input', ret);
          break;
        // two dates selected = price request
        case 2:
          if (isRange) this.$emit('input', ret);
          break;
        default:
          break;
      }
    },
    // @vuese
    // sets up all functions and config for the flatpickr
    buildFlatpickr() {
      const self = this;

      // jump to currently viewed date in case startDate is still in range of showed month
      const jumpToDate = (dateArr, fpInstance) => {
        // convert viewed date because it might be a string coming from node.js
        const viewedDate = new Date(self.viewedDate);
        if (dateArr.length) {
          const dateMax = new Date(viewedDate.getFullYear(), viewedDate.getMonth() + self.monthCount, 1, 0, 0, 0);
          const selectedStartDate = dateArr[0];
          if (selectedStartDate >= dateMax) {
            fpInstance.jumpToDate(selectedStartDate, false);
          } else {
            fpInstance.jumpToDate(viewedDate, false);
          }
          self.dayFormatCallback(dateArr);
        }
      };

      // set max Date
      this.$set(this.flatpickrBaseConfig, 'maxDate', this.getMaxDate());

      this.flatpickrBaseConfig.onReady = (dateArr, dateStr, fpInstance) => {
        this.flatpickrInstance = fpInstance;
        // init selectedStartDate and endDate
        self.selectedStartDateString = dateArr[0] ? toDateString(dateArr[0], this.$t('date:flatpickrOverlayDateFormat')) : '';
        self.selectedEndDateString = dateArr[0] ? toDateString(dateArr[1], this.$t('date:flatpickrOverlayDateFormat')) : '';

        // inline picker do not trigger onOpen => activate overlay state here
        if (self.inline) {
          fpInstance.calendarContainer.classList.add(this.variant);
          self.showOverlay = true;
          self.overlayStartDateActive = true;
          self.overlayEndDateActive = false;

          // self.dayFormatCallback(dateArr);
          self.disableMonthOverlap();
        } else {
          self.$nextTick(() => {
            if (self.fpInstance) self.fpInstance.open();
          });
        }

        self.$nextTick(() => { self.setFlatpickrInputDimensions(); });

        jumpToDate(dateArr, fpInstance);
      };

      // add vacancy callback to add classes
      this.flatpickrBaseConfig.onDayCreate = (dayObj, dayStr, fpObj, dayElem) => {
        // dayElem.classList.add(`${self.variant}`); // add classname from computed variant for experiment styling
        dayElem.classList.add(this.variant);
        if (self.hasVacancyData) {
          const status = self.helper.getDateStatus(dayElem.dateObj);
          if (status === false || status === 'n') dayElem.classList.add('flatpickr-disabled');
          else dayElem.classList.add(`day-${status}`);
        }

        if (dayElem.dateObj.getDay() === 6 && !self.hasVacancyData) {
          dayElem.classList.add('suggested-arrival');
        }
      };
      this.flatpickrBaseConfig.onOpen = (dateArr, dateStr, fpInstance) => {
        self.dayFormatCallback(dateArr);
        self.disableMonthOverlap();

        fpInstance.calendarContainer.classList.add(this.variant);

        if (self.forceRightMost) {
          fpInstance.calendarContainer.classList.add('forceRightMost');
          fpInstance.calendarContainer.style.right = '0px';
        }

        // open flatpickr-overlay
        self.showOverlay = true;
        self.overlayStartDateActive = true;
        self.overlayEndDateActive = false;

        this.$store.commit('SET_FLATPICKR_OPENED');

        jumpToDate(dateArr, fpInstance);
      };
      this.flatpickrBaseConfig.onChange = (dateArr, dateStr, fpInstance) => {
        if (dateArr.length < 2 && this.mode === 'range') return;
        jumpToDate(dateArr, fpInstance);
      };
      this.flatpickrBaseConfig.onClose = () => {
      // close flatpickr-overlay unless inline
        if (!self.inline) this.showOverlay = false;
      // for some weird reason flatpickr inline trigger onClose although it never actually "closes"
      };

      this.flatpickrBaseConfig.onMonthChange = (dateArr, dateStr, fpInstance) => {
        // emit the currently viewed month in picker, can be sued to sync multiple pickers on site
        self.$emit('on-view-change', new Date(fpInstance.currentYear, fpInstance.currentMonth));
        self.dayFormatCallback(dateArr); self.disableMonthOverlap();
      };

      this.flatpickrBaseConfig.onYearChange = (dateArr, dateStr, fpInstance) => {
        self.$emit('on-view-change', new Date(fpInstance.currentYear, fpInstance.currentMonth));
        self.dayFormatCallback(dateArr); self.disableMonthOverlap();
      };

      if (this.hasVacancyData) {
        this.processVacancyData();
      } else {
        if (this.mode === 'range') this.dayFormatCallback = this.disableToday;
        this.disableToday();
      }

      self.$set(self.flatpickrBaseConfig, 'dateFormat', self.$t('date:calendarDateFormat'));
      // using getResource for flatpickr language because we need the full object
      self.$set(self.flatpickrBaseConfig, 'locale', flatpickrLocale[this.currentLanguage]);
      // this.lang.pickerInputAlternate = self.i18nHelper.get('flatpickr:pickerInputAlternate');
    },
    // @vuese
    // increase key to force a picker re-render
    // required because updating the picker sometimes does not work properly
    forcePickerReRender() {
      this.flatpickrUpdateKey += 1;
    },
    // @vuese
    // rebuild config after language change to get correct locale data
    updateLocale() {
      // update helper
      this.i18nHelper = i18nHelper(this.$i18n.i18next);

      this.flatpickrBaseConfig.locale = flatpickrLocale[this.currentLanguage];
    },
  },
};
</script>

<style scoped>
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
</style>

<style lang="scss">

/* FLATPICKER */

.booking {
  .flatpickr-calendar {
    &:not(.inline) {
      @media #{$media_sm_max} {
        width: 100%;
        left: 0 !important;
      }
      // works for extreme small devices 300 <= 270
      @media only screen and (max-width: 300px) {
        width: auto;
        left: 0 !important;

        .flatpickr-weekdays {
          width: 262px;
        }

        .flatpickr-next-month {
          right: 40px;
        }

        .dayContainer {
          width: 263.875px;
          min-width: 263.875px;
          max-width: none;
        }

        .flatpickr-day {
          max-width: 33px;
          height: 33px;
          line-height: 33px;
        }
      }
    }
  }
}

.flatpickr-wrapper {
  display: block;
  width: 100%;
}

.flatpickr-calendar {
  box-shadow: 1px 0 0 $color_grey_bg, -1px 0 0 $color_grey_bg, 0 1px 0 $color_grey_bg, 0 -1px 0 $color_grey_bg, 0 3px 13px rgba(0, 0, 0, 0.08);

  /* Bordershadow should not disappear when clicking on the calender */
  &[tabindex]:focus {
    box-shadow: 1px 0 0 $color_grey_bg, -1px 0 0 $color_grey_bg, 0 1px 0 $color_grey_bg, 0 -1px 0 $color_grey_bg, 0 3px 13px rgba(0, 0, 0, 0.08);
  }

  &::after,
  &::before {
    display: none !important;
  }

  &.inline {
    height: 309px;
  }

  &:not(.inline) {
    @media #{$media_sm_max} {
      width: 100%;

      .flatpickr-innerContainer {
        display: flex;
        justify-content: space-evenly;
      }
    }
  }

  /*
  &.inline {
    .flatpickr-months {
      > :not(:first-child):not(:last-child):not(:nth-child(2)):not(:nth-last-child(2)) {
        display: none;
      }

      > :nth-last-child(2)::before {
        content: "-";
        float: left;
        height: 34px;
        line-height: 1;
        padding-top: 10px;
      }
    }

    .flatpickr-weekdays {
      > :not(:first-child):not(:last-child) {
        display: none;
      }
    }

    .flatpickr-days {
      flex-wrap: wrap;

      .dayContainer {
        margin-top: 10px;
      }
    }
  }
  */

  .flatpickr-days {
    padding-bottom: 0.5em;
    cursor: default;
  }

  .flatpickr-prev-month,
  .flatpickr-next-month {
    svg {
      display: block;
    }
  }

  .flatpickr-months {
    .numInputWrapper:hover {
      background: none;
    }

    input.cur-year {
      pointer-events: none;
    }

    .flatpickr-current-month span.cur-month:hover {
      background: none;
    }

    .arrowUp,
    .arrowDown {
      display: none;
    }
  }

  &.highlight-sat {
    .flatpickr-day:nth-child(7n+1) {
      background: #fff;
      border-color: transparent;
      color: $color_datepicker;
    }
  }

  &.multiMonth {
    > div.flatpickr-innerContainer {
      > div.flatpickr-rContainer {
        > div.flatpickr-days {
          > div.dayContainer {
            span.flatpickr-day.inRange:nth-child(7n+7) {
              box-shadow: -5px 0 0 $color_datepicker_100, 0 0 0 $color_datepicker_100 !important;
            }
          }
        }
      }
    }

    .flatpickr-day.inRange:nth-child(7n+1) {
      box-shadow: 0 0 0 $color_datepicker_100, 5px 0 0 $color_datepicker_100 !important;
    }
  }

  .flatpickr-day {
    @include font_regular;

    color: var(--color_text);
    margin: 0.25em auto;

    #app:not(.isTouchDevice) &:hover {
      border: 1px solid $color_datepicker;
      background: $color_datepicker;

      @include font_bold;

      color: #fff;
    }

    &.flatpickr-disabled {
      &,
      &:hover,
      &:focus,
      #app:not(.isTouchDevice) &:hover {
        background: none;
        color: $color_grey_300 !important;
        position: relative;
        border-color: transparent;
        cursor: default;

        &::before {
          position: absolute;
          content: "";
          left: 0;
          top: 50%;
          right: 0;
          border-top: 1px solid rgba(#000, 0.15);
          transform: rotate(-45deg);
        }
      }
    }

    &.endRange.startRange + .endRange:not(:nth-child(7n + 1)),
    &.selected.startRange + .endRange:not(:nth-child(7n + 1)),
    &.startRange.startRange + .endRange:not(:nth-child(7n + 1)) {
      box-shadow: -10px 0 0 $color_datepicker;
      color: #fff;
    }

    &.day-y.day-disabled,
    &.day-Y.day-disabled {
      color: var(--color_text);
      cursor: default;
    }

    &.day-Y:not(.startRange):not(.endRange):not(.day-disabled) {
      @include font_bold;

      color: $color_datepicker;
    }

    &.day-Y:not(.nextMonthDay):not(.inRange):not(.startRange):not(.endRange):not(.day-disabled),
    &.day-y:not(.day-disabled) {
      background: #fff;
      border-color: transparent;
      color: $color_datepicker;
    }

    &.selected,
    &.startRange,
    &.endRange {
      &,
      &:hover,
      &:focus,
      &.day-Y {
        border-color: $color_datepicker;
        background: $color_datepicker;

        @include font_bold;

        color: #fff !important;
      }
    }

    &.inRange {
      border-color: $color_datepicker_100 !important;
      background: $color_datepicker_100 !important;
      box-shadow:
        5px 0 0 $color_datepicker_100,
        5px 0 0 $color_datepicker_100;
      box-shadow:
        -5px 0 0 $color_datepicker_100,
        5px 0 0 $color_datepicker_100;
    }

    &.nextMonthDay,
    &.prevMonthDay {
      visibility: hidden;
    }
  }
}

.flatpickr-input {
  overflow: hidden;
}

.vacancy-form {
  font-size: var(--datepickr_fontsize);
}

.flatpickr-input-overlay {
  position: absolute;
  //display: none;
  z-index: 11; // any z-index > 10 will do; 10 is the delete button in inputs
  top: 1px;
  right: 1px;
  display: flex;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-align: left !important;
  background: #fff;
  font-size: inherit !important;
  padding: 0.3em 0.4em;

  .flatpickr-input-overlay-separator {
    padding: 0 0.15em;
  }

  div {
    padding: 0 0.15em 0.1em;
    background: #fff;
    display: flex;
    align-items: center;

    &.active {
      background-color: $color_datepicker;
      color: #fff;

      @include radius(0.15em);

      padding: 0 0.35em 0.1em;
    }
  }
}

.flatpickr-ignore {
  cursor: pointer;
}

.flatpickr-calendar.forceRightMost.arrowTop {
  &::before,
  &::after {
    display: none !important;
  }
}

.flatpickr-day {
  line-height: 37px;

  &.day-disabled,
  &.flatpickr-disabled {
    pointer-events: none !important;

    ::before {
      pointer-events: none !important;
    }

    &:not(.inRange):not(.startRange):not(.endRange):not(.selected) {
      background: transparent !important;
      border-color: transparent !important;
    }
  }

  &.prevMonthDay,
  &.nextMonthDay {
    &,
    &:hover,
    &:active,
    &.flatpickr-disabled,
    &.flatpickr-disabled:hover,
    &.flatpickr-disabled:active {
      background: #fff !important;
      color: #fff !important;
      border-color: #fff !important;
      pointer-events: none;

      &::before {
        border: 0 !important;
      }
    }
  }
}

.search-picker-modal,
.object-vacancy-modal {
  .flatpickr-calendar {
    margin: $margin_content 0 0 0;
  }

  .flatpickr-base {
    margin: 11px 0 0;

    .input-group {
      .input-group-text,
      .form-control {
        border: 1px solid $color_grey_100 !important;
      }
    }

    .form-group {
      position: relative;
    }
  }

  .flatpickr-month-navigation {
    display: block;
    width: 100%;
    margin: 15px 0 0 0;

    .prev,
    .next {
      float: left;
      background: $color_datepicker;
      color: #fff;
      padding: 0.25em 0.75em 0.25em 0.5em;

      @include radius($radius_buttons);
      @include font_bold;

      cursor: pointer;
    }

    .next {
      float: right;
      padding: 0.25em 0.5em 0.25em 0.75em;
    }
  }

  .btn.btn-primary {
    font-size: $font_size_m !important;
    border-radius: $radius_buttons !important;
    padding: 0.5em !important;
  }
}

:not(.object) {
  .flatpickr-calendar {
    .flatpickr-day {
      &.suggested-arrival:not(.day-n):not(.flatpickr-disabled):not(.selected):not(.inRange):not(.startRange):not(.endRange):not(.day-Y):not(.day-n) {
        background: mix($color_datepicker_100, #fff, 50%);

        &:hover {
          border: 1px solid #e6e6e6;
          background: #e6e6e6;
        }
      }
    }
  }
}

.showInvalid.invalid-feedback {
  display: block;
}

</style>
