/*

@@author        j0Shi
@@date          2019.07.04
@@desc          Several functions to help with vacancy functionality within flatpickr
@@usedby        FlatPickrBase -> ObjectVacancyForm -> Object

*/

import {
  fromAPIDateString,
  toAPIDateString,
} from 'utils/DateHelper';
import { addDays, differenceInDays } from 'date-fns';

const vacancyHelper = (data = {}) => {
  /**
   *
   * this defines the max date we want to allow in the flatpickr
   * without a limitation from vacancy
   * Must be a Date Object
   *
   */
  const getMaxDay = addDays(new Date(), 542);

  /**
   *
   * @param {Array} codes
   * Accepts an array of status code
   * Returns Array of matching Date Objects from data set
   *
   */
  const getDatesByStatusCodes = (codes = []) => Object.keys(Object.keys(data.days)
    .reduce((r, e) => {
      if (codes.includes(data.days[e].status)) r[e] = data.days[e];
      return r;
    }, {}))
    .map((entry) => fromAPIDateString(entry));

  /**
     *
     * @param {Array} codes
     * @param {Date} startDay
     * returns the next occurance of a given status code as Date Object
     * starting from a date in the data set
     * if not successful it returns the max date
     */
  const getNextDayByStatusCodes = (codes = [], startDay = new Date()) => {
    const days = Object.keys(Object.keys(data.days)
      .reduce((r, e) => {
        if (codes.includes(data.days[e].status)
          && differenceInDays(fromAPIDateString(e), startDay) < 0) r[e] = data.days[e];
        return r;
      }, {}))
      .map((entry) => fromAPIDateString(entry));

    if (days.length) return days[0];
    return getMaxDay;
  };

  /**
   *
   * returns the last day in the data set as Date Object or max day
   *
   */
  const getLastDayInData = () => fromAPIDateString(data.maxDate) || getMaxDay;

  /**
   *
   * returns the last arrival day in the data set as Date Object or max day
   *
   */
  const getLastArrivalDayInData = () => {
    if (typeof data.days === 'undefined') return getMaxDay;
    const dayKeys = Object.keys(data.days).filter((key) => data.days[key].status === 'Y').sort().reverse();
    if (dayKeys.length) return addDays(fromAPIDateString(dayKeys[0]), 30);
    return getLastDayInData;
  };
  const lastArrivalDayInData = getLastArrivalDayInData();

  /**
   *
   * @param {Char} code
   * @param {Date} date
   * checks a date in the data set for a status code and returns true|false
   *
   */
  const dateHasStatus = (date = new Date(), code = 'Y') => {
    const apiDateString = toAPIDateString(date);
    return typeof data.days[apiDateString] !== 'undefined' && data.days[apiDateString].status === code;
  };

  /**
   *
   * @param {Date} date
   * returns a status to a date in the data set
   *
   */
  const getDateStatus = (date = new Date()) => {
    const apiDateString = toAPIDateString(date);
    if (typeof data.days[apiDateString] === 'undefined') return 'n';
    if (data.days[apiDateString].season === null) return 'n';
    return data.days[apiDateString].status;
  };

  /**
   *
   * @param {Date} date
   * returns data to a date in the data set
   *
   */
  const getDateData = (date = new Date()) => {
    const apiDateString = toAPIDateString(date);
    if (typeof data.days[apiDateString] === 'undefined') return {};
    return data.days[apiDateString];
  };

  return {
    getDatesByStatusCodes,
    getNextDayByStatusCodes,
    getLastDayInData,
    lastArrivalDayInData,
    getMaxDay,
    dateHasStatus,
    getDateStatus,
    getDateData,
  };
};
export default vacancyHelper;
