export default {
  computed: {
    required() {
      if (typeof this.rules.required !== 'undefined') { return this.rules.required ? ' *' : ''; }// rules as object
      if (JSON.stringify(this.rules).indexOf('required') >= 0) { return ' *'; } // rules as string
      return '';
    },
    label() {
      return `${this.name}${this.required}`;
    },
    computedPlaceholder() {
      if (this.placeHolder) { return this.placeHolder + this.required; }
      return this.label;
    },
  },
};
